/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React, { useRef, useEffect } from "react"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {

  const scrolled = useRef(null)

    useEffect(() => {
        const headID = document.getElementsByTagName('head')[0]

        document.addEventListener('scroll', function (e) {
            if (!scrolled.current) {
                scrolled.current = true

                // Google Ads
                const gaScript = document.createElement('script')
                gaScript.async = true
                gaScript.crossorigin = 'anonymous'
                gaScript.src =
                    'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8460495181619769'
                gaScript.type = 'text/javascript'
                headID.appendChild(gaScript)
            }
        })
    }, [])

  return (
    <>
      <Header />
      <main className="relative min-h-screen">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
