/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-config/
 */

/**
 * @type {import('gatsby').GatsbyConfig}
 */
module.exports = {
  siteMetadata: {
    title: `Party Hardy`,
    description: `Party Hardy is a platform for those who love video games, development, and the gaming community.`,
    author: `@partyhardy`,
    siteUrl: `https://partyhardy.app`,
  },
  plugins: [
    'gatsby-plugin-postcss',
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-sitemap`,
    {
      resolve: "gatsby-plugin-firebase-v9.0",
      options: {
        credentials: {
          databaseURL: "https://game-it-373522.firebaseio.com/",
          apiKey: "AIzaSyBB2am0Ft953CMzFeYS5Be5cx74IoRaNMs",
          authDomain: "game-it-373522.firebaseapp.com",
          projectId: "game-it-373522",
          storageBucket: "game-it-373522.appspot.com",
          messagingSenderId: "81161746948",
          appId: "1:81161746948:web:7772dcb4bca3a9b5555351",
          measurementId: "G-10CNT8RDDT",
        }
      }
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: false,
        sitemap: 'https://partyhardy.app/sitemap/sitemap-index.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Party Hardy`,
        short_name: `party`,
        start_url: `/`,
        background_color: `#F25656`,
        theme_color: `#F25656`,
        display: `minimal-ui`,
        icon: `src/res/favicon.png`,
      },
    },
  ],
}
