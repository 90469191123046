import * as React from "react"
import { Link } from "gatsby"

import Logo from "../res/logo.svg";

const Footer = ({ siteTitle }) => {

  return <footer aria-label="Site Footer" class="bg-gray-100 p-8">
    <p class="text-center text-sm text-gray-500 lg:text-right">
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
    </p>
</footer>

}

export default Footer
