import * as React from "react"
import { Link } from "gatsby"

import Logo from "../res/logo.svg";
import Search from "../components/search"

const Header = ({  }) => {

  return <header className="sticky top-0 z-50 bg-white shadow" aria-label="Site Header">
    <div
      className="mx-auto flex h-16 w-full items-center gap-8 px-4 sm:px-6 lg:px-8"
    >
      <a className="block text-teal-600" href="/">
        <span className="sr-only">Home</span>
        <img alt="Party Hardy's Logo" src={Logo} className="h-10" />
      </a>

      <div className="flex flex-1 items-center justify-end md:justify-between">
        <Search width="w-full max-w-lg"></Search>

        <div className="flex items-center gap-4">
          <div className="sm:flex sm:gap-4">
            <a
              className="block rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
              href="/coming-soon"
            >
              Login
            </a>
            <a
              className="hidden rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-teal-600 transition hover:text-teal-600/75 sm:block"
              href="/coming-soon"
            >
              Register
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>

}

export default Header
